<template>
    <div v-if="dataSource" class="p-relative">

        <Toolbar :isList="allowCreate" :menu="dataSource.Data.Menu" v-on:toolbar-button-click="onToolbarClick">

            <div class="top-btns-bar-right-section">

                <v-text-field
                    v-model="search"
                    append-icon="fas fa-search"
                    :label="$t('Что_ищем')"
                    hide-details
                    class="search-in-tooltip"
                    solo
                    flat
                    clearable
                ></v-text-field>
            </div>

        </Toolbar>

        <FilterPanel
            v-if="filterDataSource"
            :dataSource="filterDataSource"
            v-bind:status.sync="collection"
            v-bind:substatus.sync="status"
        />

        <component 
            :is="collection"
            :search="search"
            :status="status"
            ref="currentComponent"
        />

    </div>
</template>

<script>
import _ from 'lodash';
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import { mapGetters } from 'vuex';
import FilterPanel from '@/components/FilterPanel.vue';
import { mapActions } from 'vuex';
import Toolbar from '@/components/Toolbar.vue';
import AllMyReport from './lists/AllMyReport.vue';
import MyCreated from './lists/MyCreated.vue';
import MyExecution from './lists/MyExecution.vue';

export default {
    name: "ReportQuerysList",
    components: {
        FilterPanel,
        Toolbar,
        MyCreated,
        MyExecution,
        AllMyReport
    },
    data () {
        return {
            cancellationTokenSorce: null,
            dataSource: null,
            filterDataSource: null,
        }
    },
    computed: {
        ...mapGetters('auth', { userInfo: 'getUserInfo'}),
        allowCreate(){
            return this.userInfo?.permissions && Array.from(this.userInfo?.permissions).includes('ReportGenerationModule')
        },  
        collection: {
            get: function() {
                return this.$store.getters['reportsgeneration/getCollection'];
            },
            set: function(newValue) {
                this.$store.commit('reportsgeneration/SET_COLLECTION', newValue);
            }
        },
        status: {
            get: function() {
                var status = this.$store.getters['reportsgeneration/getStatus'];
                return status ?? ""; 
            },
            set: function(newValue) {
                if (newValue) {
                    this.$store.commit('reportsgeneration/PUSH_STATUS', { collection: this.collection, value: newValue } );

                    if (typeof this.$refs.currentComponent?.resetPage === "function") { 
                        this.$refs.currentComponent.resetPage();
                    }
                }
            }
        },
        search: {
            get: function() {
                var search = this.$store.getters['reportsgeneration/getSearch'];
                return search ??  "";
            },
            set: _.debounce(function(v) {                
                if (typeof this.$refs.currentComponent?.resetPage === "function") { 
                    this.$refs.currentComponent.resetPage();
                }
                this.$store.commit('reportsgeneration/PUSH_SEARCH', { collection: this.collection, value: v } );
            }, 500)
        },
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        onToolbarClick(event, btn) {
            this[btn.Action]();
        },
        async loadCollectionData () {
            
            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('Обновление предыдущей категории прервано из-за выбора новой категории');

            this.cancellationTokenSorce = Axios.CancelToken.source();    

            let response = await httpAPI({
                url: `api/actions/collection?type=Documents.ReportGeneration`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            if (response) {
                await this.loadFilters();
                this.dataSource = response.data.payload;
            }
        },
        async loadFilters() {
            let filterResponse = await httpAPI({
                url: `/api/report/filter`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            if (filterResponse) {
                var permissions = (this.$store.getters['auth/getUserInfo'])?.permissions ?? [];
                filterResponse.data.payload.filter.items = filterResponse.data.payload.filter.items.filter(i => this.$_.difference(i.requires, permissions).length === 0);
                this.filterDataSource = filterResponse.data.payload;
            }
        },
        async Refresh() {
            await this.loadFilters();
            this.$refs.currentComponent.getData(true);
        }
    },
    watch: {
        collection : {
            handler () {
                this.loadCollectionData();
            }
        }
    },
    async created () {
        this.setOverlayVisible({ visible: true });
        try
        {
            await this.loadCollectionData();
        }
        finally
        {
            this.setOverlayVisible({ visible: false });
        }
    }
}

</script>